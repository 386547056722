<template>

<!-- 消息中心 -->
  <div class="container">
    <div class="header-container">
      <el-select v-model="typeDaya" clearable placeholder="请选择" @change="editType">
        <el-option
          v-for="item in type"
          :key="item.type"
          :label="item.name"
          :value="item.type">
        </el-option>
      </el-select>
    </div>
    <div class="body-container">
      <el-table
        :data="AdministratorGetSchoolMessageData"
        stripe
        style="width: 100%">
        <el-table-column
          prop="createTime"
          label="开始时间">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="修改时间">
        </el-table-column>
         <el-table-column
          show-overflow-tooltip
          prop="message"
          label="消息">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="remark"
          label="备注">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          label="状态">
          <template slot-scope="scope">
            {{scope.row.statusText}}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="edit(scope.row)"
              >修改</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer-container">
      <pagination :current-page="show.startPage" :total="show.totalPage"  @currentChange="editStartPage" @sizeChange="sizeChange"></pagination>
    </div>
    <el-dialog
      title="消息编辑"
      :visible.sync="dialogVisible"
      width="50%">
      <div>
        <div style="  display: flex; margin-bottom: 20px">
          <p style="margin-right: 20px;">{{showItem.message}}</p>
          <el-select v-model="showItem.status" placeholder="请选择">
            <el-option
              v-for="item in type"
              :key="item.type"
              :label="item.name"
              :value="item.type">
            </el-option>
          </el-select>
        </div>
        <el-input maxlength="100" type="textarea" resize="none" rows="3" placeholder="请输入备注" v-model="showItem.remark">
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// import storage from '@/globals/storage/index.js'
import AdministratorService from '@/globals/service/management/Administrator.js'
import messageCenterService from '@/globals/service/management/messageCenter.js'
import newDate from '@/utils/newDate.js'
export default {
  data () {
    return {
      showItem: {},
      dialogVisible: false,
      typeDaya: null,
      type: [
        {
          type: 0,
          name: '未读'
        },
        {
          type: 1,
          name: '已读'
        },
        {
          type: 2,
          name: '待处理'
        },
        {
          type: 3,
          name: '不处理'
        },
        {
          type: 4,
          name: '已处理'
        }
      ],
      AdministratorGetSchoolMessageData: [],
      show: {
        startPage: 1,
        pageSize: 8,
        isGovernment: 0,
        totalPage: 1
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    sizeChange (val) {
      this.show.pageSize = val
      this.getData()
    },
    editData () {
      messageCenterService.editSchoolMessage(this.showItem).then(res => {
        this.showItem = {}
        this.dialogVisible = false
        this.getData()
        this.$message({
          type: 'success',
          message: '编辑成功!'
        })
      })
    },
    editStatus (item) {
      switch (item.status) {
        case 0 :
          item.statusText = '未读'
          break
        case 1 :
          item.statusText = '已读'
          break
        case 2 :
          item.statusText = '待处理'
          break
        case 3 :
          item.statusText = '不处理'
          break
        case 4 :
          item.statusText = '已处理'
          break
      }
    },
    edit (item) {
      this.showItem = item
      this.dialogVisible = true
    },
    editType (status) {
      if (!status && status === '') return this.getAll()
      this.show = {
        startPage: 1,
        pageSize: 8,
        isGovernment: 0,
        totalPage: 1
      }
      messageCenterService.searchSchoolMessage({ status }).then(res => {
        this.AdministratorGetSchoolMessageData = res.data.map(item => {
          if (item.createTime) item.createTime = newDate.formatTime(new Date(Number(item.createTime)))
          if (item.updateTime) item.updateTime = newDate.formatTime(new Date(Number(item.updateTime)))
          if (!item.remark) item.remark = '-'
          this.editStatus(item)
          return item
        })
      })
    },
    getAll  () {
      this.show = {
        startPage: 1,
        pageSize: 8,
        isGovernment: 0,
        totalPage: 1
      }
      this.getData()
    },
    editStartPage (index) {
      this.show.startPage = index
      this.getData()
    },
    getData () {
      AdministratorService.AdministratorGetSchoolMessage(this.show).then(res => {
        this.AdministratorGetSchoolMessageData = res.data.schoolMessageList.map(item => {
          if (item.createTime) item.createTime = newDate.formatTime(new Date(Number(item.createTime)))
          if (item.updateTime) item.updateTime = newDate.formatTime(new Date(Number(item.updateTime)))
          if (!item.remark) item.remark = '-'
          this.editStatus(item)
          return item
        })
        this.show.totalPage = res.data.totalPage
      })
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  min-height: 100%;
  background-color: #fff;
  padding: 0 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .header-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 120px;
    .seek-container{
      display: flex;
      align-items: center;
      .seek-section{
        border: 1px solid #34BBEC;
        border-radius: 20px;
        display: flex;
        align-items: center;
        padding: 0 22px;
        color: #333;
        /deep/.el-input__inner{
          border: none !important;
          padding: 0 10px !important;
        }
        .el-icon-search{
          color: #999999;
        }
      }
      .seek-button{
        width: 80px;
        background-color: #34BBEC;
        margin-left: 20px;
        border-radius: 20px;
        color: #fff;
      }
    }
    .add-container{
      display: flex;
      .addUser-button{
        background-color: #34BBEC;
        border-radius: 20px;
        color: #fff;
        margin-left: 10px;
      }
      .el-input{
        width: 300px;
        margin: 0 5px;
      }
    }
  }
  .body-container{
    margin-top: 30px;
    flex: 1;
  }
  .footer-container{
    display: flex;
    width: 100%;
    padding: 33px 0;
    .el-button{
      margin-right: 160px;
    }
  }
}
</style>
